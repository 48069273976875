<template>
  <div>
    
  <v-data-table
    :headers="headers"
    :items="useraccessprivileges"
    sort-by="prog_code"
    class="elevation-1"
    dense
    :search="search"
    
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>User Access Maintenance</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field> 
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        color="primary"
        class="mr-2"
        small
        @click="editItem(item)"
      >
         edit
      </v-icon>
      <v-icon
        small
        color="red"
        @click="deleteItem(item)"
      >
        delete
      </v-icon>
    </template>
  </v-data-table>
  <create-useraccessprivilege></create-useraccessprivilege>
  </div>
</template>

<script>
import createUseraccessprivilege from './createUseraccessprivilege.vue';
import { mapGetters } from 'vuex';


export default {
	components: {
		'create-useraccessprivilege': createUseraccessprivilege
	},
	data(){
		return{
			search: ''
		};
	},
	computed: {
		...mapGetters({
			useraccessprivileges: 'useraccessprivilege/useraccessprivileges',
			headers: 'useraccessprivilege/headers',
			editedItem: 'useraccessprivilege/editedItem',
			defaultItem: 'useraccessprivilege/defaultItem',
			dialog: 'useraccessprivilege/dialog',
			editedIndex: 'useraccessprivilege/editedIndex',
			valid: 'useraccessprivilege/valid'
		}),
	},
	mounted () {
		this.$store.dispatch('useraccessprivilege/getUseraccessprivileges');
	},
	methods: {
		editItem (item) {
			this.$store.dispatch('useraccessprivilege/setEditedIndex',this.useraccessprivileges.indexOf(item));
			this.$store.dispatch('useraccessprivilege/setEditedItems',Object.assign({}, item));
			this.$store.dispatch('useraccessprivilege/setDialog',true);
			this.$store.dispatch('useraccessprivilege/setValid',true);
			this.$store.dispatch('useraccessprivilege/setformTitle','Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: 'Delete ' + item.prog_code + '?',
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('useraccessprivilege/setisLoading',true);
					this.$store.dispatch('useraccessprivilege/deleteUseraccessprivilege',item);
				}
			});
		},
	},
};
</script>

