<template>
     <v-dialog v-model="dialog" persistent max-width="70%">
     <!-- <app-loader v-model="isLoading"></app-loader> -->
      <template v-slot:activator="{ on }">
          <app-add-button v-bind:module="'useraccessprivilege'"></app-add-button>
        </template>
          <v-form
            ref="form"
            v-model="isvalid"
            lazy-validation
          >
          <v-card title>
                <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
           <v-card-text>
             
              <v-container grid-list-md>
                <v-layout wrap>
                  <!-- <v-flex xs12 sm6 md4> -->
                   <!-- <v-text-field 
                        v-model="editedItem.user_id"
                        :counter="8"
                        label="User ID"
                        required
                        maxlength=8
                    ></v-text-field>
                  </v-flex> -->
                  <v-flex xs12 sm6 md4>
                    <v-select
                        v-model="editedItem.user_id"
                        :items="userData"
                        data-vv-name="users"
                        item-text="user_id"
                        item-value="id"
                        label="User ID"
                        required
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md4>
                   <v-text-field 
                        v-model="editedItem.prog_code"
                        :counter="8"
                        label="Program Code"
                        required
                        maxlength=8
                    ></v-text-field>
                  </v-flex>
                     <v-flex xs12 sm6 md4>
                     <v-select
                          v-model="editedItem.access_level"
                          :items="accessLevel"
                          :rules="[v => !!v || 'Please select access level']"
                          label="Access Level"
                          data-vv-name="accessLevel"
                          required
                    ></v-select>
                    </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';

export default {
	components: {
		'app-add-button': AppAddButtonVue
	},
	data: () => ({
		search: null,
		isvalid: true,
		prog_codeRules: [
			v => !!v || 'User program Code access is required',
			v => (v && v.length <= 8) || 'User program Code must be less than 8 characters',
		],   
		access_levelRules: [
			v => !!v || 'User Access Level',
			v => (v && v.length <= 1) || 'User Access Level must be less than 2 characters',
		],
		userData: [],
		nextPage: ''
	}),
	mounted () {
		this.$store.dispatch('user/getUsers');
	},
	computed: {
		...mapGetters({
			useraccessprivileges: 'useraccessprivilege/Useraccessprivileges',
			userId: 'useraccessprivilege/userId',
			progCode: 'useraccessprivilege/progCode',
			accessLevel: 'useraccessprivilege/accessLevel',
			editedItem: 'useraccessprivilege/editedItem',
			defaultItem: 'useraccessprivilege/defaultItem',
			dialog: 'useraccessprivilege/dialog',
			editedIndex: 'useraccessprivilege/editedIndex',
			formTitle: 'useraccessprivilege/formTitle',
			valid: 'useraccessprivilege/valid',
			isLoading: 'app/isLoading',
			currUser: 'auth/currUser',
			users: 'user/users',
		}),
	}, watch: {
		users: {
			handler(){
				this.getData();
			}
		}
	},
	methods: {
		async getData(){
			this.userData = this.users.data;
			this.nextPage = this.users.next_page_url;
			let page = 2;

			while (!this.users.data){
				await this.$store.dispatch('users/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.userData.push(details);
					});
				});
				page++;
			}
		},
		close () {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('useraccessprivilege/setValid',true);
				this.$store.dispatch('useraccessprivilege/setDialog',false);
				this.$store.dispatch('useraccessprivilege/setEditedIndex','-1');
				this.isvalid = false;
			}, 2000);
		},
		save () {
			this.$store.dispatch('useraccessprivilege/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			this.editedItem.edit_by = this.currUser.id;
			delete this.editedItem['updated_at'];
			delete this.editedItem['created_at'];
			if(this.valid){
				if (this.editedIndex > -1) {
					this.$store.dispatch('useraccessprivilege/updateUseraccessprivilege',this.editedItem);
				} else {
					this.$store.dispatch('useraccessprivilege/saveUseraccessprivilege',this.editedItem);
				}
				setTimeout(() => {
					this.close();
				}, 3000);
			}
		},
      
	}
};
</script>
