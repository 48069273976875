<template>
	<div class="home">
		<table-useraccessprivilege></table-useraccessprivilege>
	</div>
</template>

<script>
import tableUseraccessprivilege from '@/components/pages/maint/useraccessprivilege/tableUseraccessprivilege.vue';

export default {
	components: {
		'table-useraccessprivilege': tableUseraccessprivilege,
	}
};
</script>

<style>

</style>
