<template>
	<v-btn
		class="mx-2"
		fab
		color="primary"
		right
		fixed
		bottom
		@click="newEntry"
	>
		<v-icon dark>mdi-plus</v-icon>
	</v-btn>
</template>

<script>
export default {
	props: {
		module,
	},
	methods: {
		newEntry(){
			this.$store.dispatch(this.module + '/setDialog',true);
			this.$store.dispatch(this.module + '/setformTitle','New Entry');
			this.$store.dispatch(this.module + '/setValid',false);
		},
	}
};
</script>

<style>

</style>